<template>
<v-container fluid fill-height style="padding-left: 100px; padding-right: 100px;">
			<v-row align-center justify-center>
				<v-col xs12 sm8 md4>
					<v-card>
						<v-toolbar dark>
							<v-toolbar-title>Reset my password</v-toolbar-title>
						</v-toolbar>
						<v-card-text>
							<v-form>
								<v-text-field name="email" label="email" type="email" v-model="emailInput"></v-text-field>
							</v-form>
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn text @click.prevent="CheckEmail()">
                        		Envoyer
                    		</v-btn>
						</v-card-actions>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
</template>

<script>

export default {
    data(){
        return{
            emailInput: '',
            emailArray: [],
            idArray: [],
            tokenGenerated: '',
            EmailExistenceLength: 0,
            baseUrl: window.location.protocol + '//' + window.location.hostname + ':8080/reset-password',
            fullUrl: '',
            id: 0,
        }
    },


    methods : {
        //On check l'existence de l'email dans la bdd puis on crée l'url du reset de mot de passe
        async CheckEmail(){
             const EmailChecked = await Promise.all([this.CheckEmailExistence()])
             if(EmailChecked){
                if(this.EmailExistenceLength > 0){
                    const TokenGenerate = await Promise.all([this.GenerateToken()])
                    if (TokenGenerate){ 
                        const TokenInserted = await Promise.all([this.InsertToken()])
                        if(TokenInserted){
                            const MailSent = await Promise.all([this.sendMail()])
                            if(!MailSent){
                                window.alert('error while sending the mail')
                                return
                            }else{
                                window.alert('An email with a link has been sent to you')
                            }
                        }else{
                            window.alert('error')
                            return
                        }
                    }else{
                        window.alert('error')
                        return
                    }
                }
                else{
                    window.alert('email doesn\'t exist')
                    return
                }
             }
        },
        
        //On check l'existence de l'email 
        async CheckEmailExistence(){
				console.log('email')
				const email = encodeURIComponent(this.emailInput)
				const url = this.$api.getRESTApiUri() + `/check-email-existence/${email}`
				return fetch(url)
                .then(res => res.text())
                .then((result) => {
                    const data = JSON.parse(result);
					console.log(data)
					this.EmailExistenceLength = data.length
                    this.id = data[0].user_id
					console.log(this.EmailExistenceLength);
				})
				.catch((error) => {
					console.log(error)
				});	
			},
        
            async GetPassword(){
				const email = encodeURIComponent(this.emailInput)
				const url = this.$api.getRESTApiUri() + `/get-password/${email}`
				return fetch(url)
                .then(res => res.text())
                .then((result) => {
                    const data = JSON.parse(result);
					console.log(data)
					this.passwordDatabase = data[0].password
					console.log(this.passwordDatabase)
				})
				.catch((error) => {
					console.log(error)
				});	
			},

        //Création d'un token qui donnera l'accès à la création d'un nouveau mot de passe
        async GenerateToken(){
            this.tokenGenerated = Math.random().toString(36).substr(2) + Math.random().toString(36).substr(2); // remove `0.`
            console.log(this.tokenGenerated);

        },

        //Insertion du token crée dans la bdd 
        async InsertToken(){
            const url = this.$api.getRESTApiUri() + `/insert/token`;
            fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: this.emailInput,
                    token: this.tokenGenerated,
                }),
			})
			// Converting to JSON
			.then(response => response.json())
			// Displaying results to console
			.then(json => console.log(json));		
		},

        //envoie du lien crée 
        async sendMail(){
            this.fullUrl = this.baseUrl + '?' + this.tokenGenerated + 'id=' + this.id;
            console.log(this.fullUrl)
            const lien = encodeURIComponent(this.fullUrl);
            const email = encodeURIComponent(this.emailInput);
            console.log(lien)
            const url = this.$api.getRESTApiUri() + `/envoie-email/${lien}/${email}`;
            fetch(url)
            .then(response => response.json())
			// Displaying results to console
			.then(json => console.log(json));	
        }
    }
}
</script>